/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";
import { Env } from "@utils/env";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type MIABAnalyticsFeedbackEventForm, parseMIABAnalyticsFeedbackEventForm, renderMIABAnalyticsFeedbackEventForm } from '../model/mIABAnalyticsFeedbackEventForm';
// @ts-ignore
import { type MIABCustomQuestionsForm, parseMIABCustomQuestionsForm, renderMIABCustomQuestionsForm } from '../model/mIABCustomQuestionsForm';
// @ts-ignore
import { type MIABPopularSummariesResult, parseMIABPopularSummariesResult, renderMIABPopularSummariesResult } from '../model/mIABPopularSummariesResult';
// @ts-ignore
import { type MIABSummaryForm, parseMIABSummaryForm, renderMIABSummaryForm } from '../model/mIABSummaryForm';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @param dataId 
*/
export async function bookmarkMiab(dataId: number): Promise<boolean> {
        return getHttpClient().request<boolean>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/miab/{dataId}/bookmark`
                .replace('{' + 'dataId' + '}', encodeURIComponent(String(dataId))),
            method: 'POST'
        })
        .then(response => {
               return response.data;
        });
}
/**
* 
* @param mIABSummaryForm 
*/
export async function getPopularSummaries(mIABSummaryForm: MIABSummaryForm): Promise<MIABPopularSummariesResult> {
        return getHttpClient().request<MIABPopularSummariesResult>({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/miab/getPopularSummaries`,
            method: 'POST',
            data: renderMIABSummaryForm(mIABSummaryForm)
        })
        .then(response => {
               return parseMIABPopularSummariesResult(response.data);
        });
}
/**
* 
* @param mIABAnalyticsFeedbackEventForm 
*/
export async function giveFeedback(mIABAnalyticsFeedbackEventForm: MIABAnalyticsFeedbackEventForm): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/miab/giveFeedback`,
            method: 'POST',
            data: renderMIABAnalyticsFeedbackEventForm(mIABAnalyticsFeedbackEventForm)
        });
}
/**
* 
* @param mIABCustomQuestionsForm 
*/
export async function saveCustomQuestion(mIABCustomQuestionsForm: MIABCustomQuestionsForm): Promise<void> {
        return getHttpClient().request({
            // @ts-ignore
            url: `${Env.PUBLIC_BACKEND_URL ?? ''}/gaapi/miab/saveCustomQuestions`,
            method: 'POST',
            data: renderMIABCustomQuestionsForm(mIABCustomQuestionsForm)
        });
}

