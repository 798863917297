import type { GaVueComponent } from "@/common/vueUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { GaBadge } from "@/components/general/GaBadge";
import { GaNumberedList } from "@/components/general/GaNumberedList";
import type { Store } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import type { DiscussState } from "@/components/miab/MIABPage";
import type { MIABQuestionMetadata } from "@newgenerated/shared/schema";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { saveCustomQuestion } from "@generated/api/mIABControllerApi";
import { GaFormFieldTextArea } from "@/components/form/GaFormFieldTextArea";
import { assertIsDefined } from "@utils/assertion";

function deleteQuestion(questionId: bigint | null, questions: Store<MIABQuestionMetadata[]>): void {
  assertIsDefined(questionId);
  const modified = questions.get().filter((question) => question.miabQuestionId !== questionId);
  questions.set(modified);
}

function Edit(props: { questions: Store<MIABQuestionMetadata[]> }): GaVueComponent {
  return (
    <>
      {props.questions
        .get()
        .map((question) => props.questions.sub(props.questions.get().findIndex((q2) => question.miabQuestionId === q2.miabQuestionId)))
        .map((question) => (
          <div class="mb-4 miab-questions__edit">
            <div class="miab-questions__edit-title">
              <GaFormFieldInputText value={question.get().title} onUpdateValue={question.sub("title").set} name="title" placeholder="Title" />
            </div>
            <div class="miab-questions__edit-question">
              <GaFormFieldTextArea value={question.get().question} onUpdateValue={question.sub("question").set} name="question" placeholder="Question" />
            </div>
            <div class="miab-questions__edit-delete">
              <GaButton onClick={() => deleteQuestion(question.get().miabQuestionId, props.questions)}>
                <i class="ico-trash-bin" />
              </GaButton>
            </div>
          </div>
        ))}
    </>
  );
}

async function save(state: Store<DiscussState>, dataId: number): Promise<void> {
  await saveCustomQuestion({ questions: [...state.get().questions], dataId: dataId });
  state.sub("status").set("VIEW");
}

function addQuestion(questions: Store<MIABQuestionMetadata[]>): void {
  const highestOrder = [...questions.get()].sort((a, b) => b.sortOrder - a.sortOrder)[0]?.sortOrder ?? 0;
  questions.set([...questions.get(), { title: "", question: "", miabQuestionId: null, sortOrder: highestOrder + 1 }]);
}

export function DiscussBeta(props: { state: Store<DiscussState>; dataId: number }): GaVueComponent {
  const { t } = useGaContext();
  const state = props.state.get();
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.discuss.badge")?.toUpperCase()}</GaBadge>
      </div>
      <div class="d-flex justify-content-between">
        <h2>{t("miab:page.discuss.title")}</h2>
        {state.status === "VIEW" ? (
          <GaButton variant={"primary-alt"} onClick={() => props.state.sub("status").set("EDIT")}>
            Edit
          </GaButton>
        ) : (
          <div class="d-flex gap-2">
            <GaButton onClick={() => addQuestion(props.state.sub("questions"))}>Add question</GaButton>
            <GaButton variant={"primary-alt"} onClick={() => save(props.state, props.dataId)}>
              Save
            </GaButton>
          </div>
        )}
      </div>
      <p>
        <strong>{t("miab:page.discuss.description")}</strong>
      </p>
      <div class="miab-questions">{state.status === "VIEW" ? <GaNumberedList items={state.questions.map((question) => ({ title: question.title, text: question.question }))} /> : <Edit questions={props.state.sub("questions")} />}</div>
    </>
  );
}
