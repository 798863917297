import type { ContentCardDemoProps } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { GaButton } from "@/components/general/GaButton";
import ContentCard from "@utils/vue-migration/component/cards/ContentCard.vue";
import type { PropType } from "vue";
import { defineComponent, ref } from "vue";

function ensureNotNull<T>(item: T | undefined): T {
  if (item === undefined) {
    throw new Error("Item is undefined");
  }
  return item;
}

function Demo(props: { contentCardDemoProps: ContentCardDemoProps; title: string; layout: "horizontal" | "vertical"; columnCount: number; updateColumnCount: (n: number) => void }): GaVueComponent {
  const { summaries, miabs, actionables, sketchnotes } = props.contentCardDemoProps;
  const numberOfElements = summaries.length + actionables.length + sketchnotes.length + miabs.length;

  return (
    <div class="mb-5">
      <h2>{props.title}</h2>
      <div data-card-demo="">
        <div class="demo-bar mb-3">
          <span>
            <GaBadge variant="muted">VUE</GaBadge>
          </span>
          <div class="demo-bar-controls">
            <GaButton size={"sm"} onClick={() => props.updateColumnCount(Math.min(props.columnCount + 1, numberOfElements))}>
              +
            </GaButton>
            <GaButton size={"sm"} onClick={() => props.updateColumnCount(Math.max(props.columnCount - 1, 1))}>
              -
            </GaButton>
          </div>
        </div>
        <div class="demo-flexible-columns" style={`--row-number: ${props.columnCount}`}>
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[0])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[1])} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[2])} link="/free-abstracts/123?u=gA_Demo" allowDelete={true} additionalParams={"st=ACTIONABLE&si=1"} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[3])} allowBookmark={false} allowDelete={true} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.summaries[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.actionables[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.sketchnotes[4])} layout={props.layout} />
          <ContentCard content={ensureNotNull(props.contentCardDemoProps.miabs[4])} layout={props.layout} />
        </div>
      </div>
    </div>
  );
}

export const ContentCardDemo = defineComponent({
  props: {
    contentCardDemoProps: {
      type: Object as PropType<ContentCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const columns = ref({ horizontal: 2, vertical: 3 });
    return () => (
      <div>
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Vertical"} layout={"vertical"} columnCount={columns.value.vertical} updateColumnCount={(n) => (columns.value.vertical = n)} />
        <Demo contentCardDemoProps={props.contentCardDemoProps} title={"Horizontal"} layout={"horizontal"} columnCount={columns.value.horizontal} updateColumnCount={(n) => (columns.value.horizontal = n)} />
      </div>
    );
  },
});
